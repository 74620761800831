import Vue from 'vue';
import App from './App.vue';
import './assets/styles.scss';
import { APP_NAME, APP_VERSION } from './config';
import { i18n } from './locales/i18n';
import { AppUtilsMixin } from './mixins/AppUtilsMixin';
import { DateMixin } from './mixins/DateMixin';
import vuetify from './plugins/vuetify';
import PreApp from './PreApp.vue';
import './registerServiceWorker';
import { router } from './router';
import { preRouter } from './router/preRouter';
import { AuthService } from './services/auth/AuthService';
import { DomainsService } from './services/domains/DomainsService';
import { ManifestService } from './services/manifest/ManifestService';
import * as GoogleChartsUtils from './utils/GoogleChartsUtils';
import { GoogleMapsLoader } from './utils/GoogleMapsUtils';

Vue.config.productionTip = false;

console.info(APP_NAME, APP_VERSION);

Vue.mixin(AppUtilsMixin);
Vue.mixin(DateMixin);

ManifestService.loadManifest();

function createApp(): Vue {
  return new Vue({
    i18n,
    router,
    vuetify,
    render: h => h(App),
  });
}

function createPreApp(): Vue {
  return new Vue({
    i18n,
    router: preRouter,
    vuetify,
    render: h => h(PreApp),
  });
}

async function createVueInstance(): Promise<Vue> {
  if (AuthService.isLoggedIn()) {
    await DomainsService.loadDomains();
    await GoogleChartsUtils.loadPackages();
    await GoogleMapsLoader.load();

    return createApp();
  } else {
    AuthService.signOut();

    return createPreApp();
  }
}

createVueInstance()
  .then(vue => vue.$mount('#app'))
  .catch(() => createPreApp().$mount('#app'));
