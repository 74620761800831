import Vue from 'vue';
import { formBuilder } from '../utils/FormBuilder';

export const AppUtilsMixin = Vue.extend({
  computed: {
    $formBuilder: () => formBuilder,
  },

  methods: {
    isLastItem(items: unknown[], index: number): boolean {
      return items?.length - 1 === index;
    },
    $stringify(value: unknown): string {
      return JSON.stringify(value);
    },
  },
});
