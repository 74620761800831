import { i18n } from '../locales/i18n';

const THIRTY_MINUTES_IN_SECONDS = 30 * 60;
const ONE_HOUR_IN_SECONDS = 60 * 60;
const TWO_HOURS_IN_SECONDS = 2 * 60 * 60;
const FOUR_HOURS_IN_SECONDS = 4 * 60 * 60;
const EIGHT_HOURS_IN_SECONDS = 8 * 60 * 60;

export function getProxyTTLs() {
  return [
    {
      text: i18n.t('proxy.minutes', { value: 30 }),
      proxyTTL: THIRTY_MINUTES_IN_SECONDS,
    },
    {
      text: i18n.t('proxy.hour', { value: 1 }),
      proxyTTL: ONE_HOUR_IN_SECONDS,
    },
    {
      text: i18n.t('proxy.hours', { value: 2 }),
      proxyTTL: TWO_HOURS_IN_SECONDS,
    },
    {
      text: i18n.t('proxy.hours', { value: 4 }),
      proxyTTL: FOUR_HOURS_IN_SECONDS,
    },
    {
      text: i18n.t('proxy.hours', { value: 8 }),
      proxyTTL: EIGHT_HOURS_IN_SECONDS,
    },
  ];
}
