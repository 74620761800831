import { AxiosInstance } from '../../api/axios';
import { STORAGE_PREFIX, TELENOR_HOSTNAME } from '../../config';
import { ManifestServiceInterface } from './ManifestServiceInterface';
import { GetManifest } from './models/GetManifest';

export class ManifestServiceImplementation implements ManifestServiceInterface {
  private readonly MANIFEST_BASE_URL =
    'https://1u31fuekv5.execute-api.eu-west-1.amazonaws.com/prod';

  private get manifestKey(): string {
    return `${STORAGE_PREFIX}:manifest`;
  }

  get hasManifest(): boolean {
    return localStorage.getItem(this.manifestKey) !== null;
  }

  async getManifest(): Promise<GetManifest> {
    if (this.hasManifest) {
      const manifest = JSON.parse(
        localStorage.getItem(this.manifestKey) ?? '{}'
      );

      return manifest as GetManifest;
    } else {
      const ENDPOINT = `/manifest/?hostname=${TELENOR_HOSTNAME}`;
      const { data }: { data: GetManifest } = await AxiosInstance.get(
        `${this.MANIFEST_BASE_URL}${ENDPOINT}`
      );

      return data;
    }
  }

  async loadManifest(): Promise<void> {
    const manifest = await this.getManifest();
    localStorage.setItem(this.manifestKey, JSON.stringify(manifest));
  }

  removeManifest(): void {
    localStorage.removeItem(this.manifestKey);
  }
}

export const ManifestService = new ManifestServiceImplementation();
