















import { SnackbarService } from '../../services/snackbar/SnackbarService';
import Vue from 'vue';
import { SnackbarType } from '../../services/snackbar/models/SnackbarType';

export default Vue.extend({
  name: 'TheAppSnackbar',

  watch: {
    'state.isOpen'(value: boolean) {
      if (!value) {
        SnackbarService.close();
      }
    },
  },

  computed: {
    iconType(): string {
      switch (this.state.type) {
        case SnackbarType.Success:
          return 'mdi-check-bold';

        case SnackbarType.Warning:
          return 'mdi-alert-outline';

        case SnackbarType.Error:
          return 'mdi-alert';

        case SnackbarType.Normal:
          return 'mdi-information-outline';

        default:
          return '';
      }
    },
  },

  data() {
    return {
      state: SnackbarService.getState(),
    };
  },
});
