import { AxiosInstance } from '../../api/axios';
import { CLOUD_REST_API_BASE_URL } from '../../config';
import { AuthService } from '../auth/AuthService';
import { DomainsServiceInterface } from './DomainsServiceInterface';

function resolveDomainPath(
  data: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  identifier: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  path = ''
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const { attributes: rootAttributes = {} } = data ?? {};

  if (rootAttributes && rootAttributes?.name) {
    path += rootAttributes.name;
    path += ' | ';
  }

  const currentDomain = data[identifier];
  if (currentDomain) {
    const { attributes = {} } = currentDomain ?? {};
    if (attributes && attributes?.name) {
      path += attributes.name;

      return path;
    }
  }

  if (Object.keys(data).length === 0) {
    return '';
  }

  for (const datakey of Object.keys(data)) {
    const domain = data[datakey];
    const domainKeys = Object.keys(domain);

    if (domain[identifier]) {
      const { attributes: domainAttributes = {} } = domain;

      const subdomain = domain[identifier];
      const { attributes: subdomainAttributes = {} } = subdomain;

      if (domainAttributes?.name && subdomainAttributes?.name) {
        path += domainAttributes?.name + ' | ' + subdomainAttributes?.name;
        return path;
      }
    } else if (datakey !== 'attributes' && domainKeys.length > 1) {
      const nextPath = resolveDomainPath(domain, identifier, path);

      if (nextPath) {
        return nextPath;
      }
    }
  }
}

class DomainsServiceImplementation implements DomainsServiceInterface {
  private domains = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getDomains(): Promise<any> {
    const ENDPOINT = '/domains';
    const { data } = await AxiosInstance.get(
      `${CLOUD_REST_API_BASE_URL}${ENDPOINT}`,
      {
        headers: {
          authorization: AuthService.getToken(),
        },
      }
    ).catch(() => ({ data: {} }));

    let domains = data;

    if (domains.root) {
      delete domains.root?.attributes;
      domains = domains.root;
    }

    return domains;
  }

  async loadDomains(): Promise<void> {
    const domains = await this.getDomains();
    this.domains = domains;
  }

  getDomainPath(domain: string): string {
    try {
      return resolveDomainPath(this.domains, domain) ?? '';
    } catch (error) {
      return '';
    }
  }
}

export const DomainsService = new DomainsServiceImplementation();
