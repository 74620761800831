import { SnackbarState } from './models/SnackbarState';
import { SnackbarType } from './models/SnackbarType';
import { SnackbarServiceInterface } from './SnackbarServiceInterface';

class SnackbarServiceImplementation implements SnackbarServiceInterface {
  private readonly defaultTimeoutInMs = 3000;
  private state: SnackbarState = {
    isOpen: false,
    text: null,
    type: SnackbarType.Normal,
    timeout: this.defaultTimeoutInMs,
  };

  getState(): SnackbarState {
    return this.state;
  }

  open(text: unknown, type: SnackbarType, timeoutInMs?: number): void {
    Object.assign(this.state, {
      isOpen: true,
      text,
      type,
      timeout: timeoutInMs ?? this.defaultTimeoutInMs,
    } as SnackbarState);
  }

  close(): void {
    Object.assign(this.state, {
      isOpen: false,
      text: null,
      type: SnackbarType.Normal,
      timeout: this.defaultTimeoutInMs,
    });
  }
}

export const SnackbarService = new SnackbarServiceImplementation();
