import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { UserVuetifyPreset } from 'vuetify';
import { LangTranslator } from 'vuetify/types/services/lang';
import { i18n } from '../locales/i18n';

Vue.use(Vuetify);

const swegonColors = {
  primary: '#39aa0d',
  secondary: '#277d32',
  accent: '#434343',
  error: '#cc1f22',
  warning: '#f3ce3c',
  info: '#18b8d7',
  success: '#277d32',
  anchor: '#277d32',
};

const vuetifyOptions: Partial<UserVuetifyPreset> = {
  lang: {
    t: ((key, ...params) => i18n.t(key, params)) as LangTranslator,
  },
  theme: {
    themes: {
      light: {
        ...swegonColors,
      },
    },
  },
};

export default new Vuetify(vuetifyOptions);
