import { i18n } from '../locales/i18n';
import { ProductType } from '../models/ProductType';

export const productTypes = [
  {
    productType: ProductType.GoldF,
    text: i18n.t('product.goldF'),
  },
  {
    productType: ProductType.GoldE,
    text: i18n.t('product.goldE'),
  },
  {
    productType: ProductType.GoldD,
    text: i18n.t('product.goldD'),
  },
  {
    productType: ProductType.GoldC,
    text: i18n.t('product.goldC'),
  },
  {
    productType: ProductType.GoldB,
    text: i18n.t('product.goldB'),
  },
  {
    productType: ProductType.GoldLP,
    text: i18n.t('product.goldLP'),
  },
  {
    productType: ProductType.Compact,
    text: i18n.t('product.compact'),
  },
  {
    productType: ProductType.Superwise2017,
    text: i18n.t('product.superwise2017'),
  },
  {
    productType: ProductType.Superwise2010,
    text: i18n.t('product.superwise2010'),
  },
  {
    productType: ProductType.Nestor,
    text: i18n.t('product.nestor'),
  },
  {
    productType: ProductType.Other,
    text: i18n.t('product.other'),
  },
];

export const historyIntervals = [
  {
    text: i18n.t('historyInterval.10minutes'),
    value: '10m',
  },
  {
    text: i18n.t('historyInterval.hour'),
    value: '1h',
  },
  {
    text: i18n.t('historyInterval.day'),
    value: '1d',
  },
  {
    text: i18n.t('historyInterval.week'),
    value: '1w',
  },
  {
    text: i18n.t('historyInterval.month'),
    value: '1M',
  },
];
