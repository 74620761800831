



















































































































import Vue from 'vue';
import {
  getLocalStorageLanguage,
  setLocalStorageLanguage,
  supportedLanguages,
} from '../../locales/i18n';
import { AuthService } from '../../services/auth/AuthService';
import { isDefined } from '../../utils/Utils';

export default Vue.extend({
  name: 'TheAppNavbar',

  data() {
    return {
      languages: supportedLanguages,
    };
  },

  watch: {
    '$i18n.locale': {
      immediate: true,
      async handler(newLanguage: string, oldLanguage: string) {
        const localStorageLanguage = getLocalStorageLanguage();
        if (
          !oldLanguage &&
          localStorageLanguage &&
          localStorageLanguage !== this.$i18n.locale
        ) {
          this.$i18n.locale = localStorageLanguage;
        }

        setLocalStorageLanguage(newLanguage);
      },
    },
  },

  computed: {
    currentUserFullName(): string {
      const user = AuthService.getCurrentUser();

      return [user.firstName, user.lastName].filter(isDefined).join(' ');
    },
  },

  methods: {
    onClickLogout(): void {
      AuthService.signOut();
      this.$router.go(0);
    },
  },
});
