




import Vue from 'vue';
import {
  AuthService,
  DEFAULT_REFRESH_TIME_IN_MS,
} from '../../services/auth/AuthService';

const ONE_MINUTE_IN_MS = 60 * 1000;

export default Vue.extend({
  name: 'TheTokenRefresh',

  data() {
    return {
      refreshTimeLeftInMs: DEFAULT_REFRESH_TIME_IN_MS,

      refreshInterval: undefined as undefined | number,
    };
  },

  created(): void {
    this.refreshTimeLeftInMs = AuthService.getRefreshTimeInMs();
    this.setupRefreshInterval();
  },

  beforeDestroy(): void {
    window.clearInterval(this.refreshInterval);
  },

  methods: {
    setupRefreshInterval(): void {
      this.refreshInterval = window.setInterval(async () => {
        const newRefreshTimeLeftInMs =
          this.refreshTimeLeftInMs - ONE_MINUTE_IN_MS;
        AuthService.setRefreshTime(newRefreshTimeLeftInMs);
        this.refreshTimeLeftInMs = newRefreshTimeLeftInMs;

        await this.refreshAuthTokensIfNeeded();
      }, ONE_MINUTE_IN_MS);
    },
    async refreshAuthTokensIfNeeded(): Promise<void> {
      const halfRefreshTimeInMinutes =
        DEFAULT_REFRESH_TIME_IN_MS / 1000 / 60 / 2;
      const refreshTimeLeftInMinutes = this.refreshTimeLeftInMs / 1000 / 60;

      if (refreshTimeLeftInMinutes <= halfRefreshTimeInMinutes) {
        try {
          await AuthService.refreshToken();
        } catch (error) {
          AuthService.signOut();
          this.$router.go(0);
        }
      }
    },
  },
});
