import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const Login = () => import('../pages/auth/LoginPage.vue');
const SignUp = () => import('../pages/auth/SignUpPage.vue');
const ResetPassword = () => import('../pages/auth/ResetPasswordPage.vue');
const SetPassword = () => import('../pages/auth/SetPasswordPage.vue');
const ConfirmSignUp = () => import('../pages/auth/ConfirmSignUpPage.vue');

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: { name: 'Root' },
  },
  {
    path: '/',
    name: 'Root',
    redirect: { name: 'Login' },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },

  // ? The following routes are used by Telenor Connextion.
  {
    path: '/setPassword',
    name: 'SetPassword',
    component: SetPassword,
  },
  {
    path: '/verify',
    name: 'ConfirmSignUp',
    component: ConfirmSignUp,
  },
];

export const preRouter = new VueRouter({ mode: 'history', routes });
