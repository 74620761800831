/* eslint-disable prettier/prettier */
const env = process.env ?? {};

export const TELENOR_HOSTNAME = 'swegon.cc.telenorconnexion.com';

export const STORAGE_PREFIX = env.VUE_APP_STORAGE_PREFIX ?? 'SC';

export const CLOUD_REST_API_BASE_URL = 'https://h5lvibpyw8.execute-api.eu-west-1.amazonaws.com/prod';
export const BACKEND_REST_API_BASE_URL = '/api';

export const API_KEY = env.VUE_APP_API_KEY ?? 'u5seChRaJJ3DXjMfeMvGo88cDsSNGzTH4YWf83mn';
export const GOOGLE_MAPS_API_KEY = env.VUE_APP_GOOGLE_MAPS_API_KEY ?? 'AIzaSyBeZeNbCNRWeAJ_0JjugILSzxe5FdkZ0k0';

export const APP_NAME = env.VUE_APP_NAME ?? 'Swegon Connect';
export const APP_VERSION = env.VUE_APP_VERSION ?? ''