import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const Sites = () => import('../pages/SitesPage.vue');
const Site = () => import('../pages/SitePage.vue');
const Unit = () => import('../pages/units/UnitPage.vue');
const RegisterSite = () => import('../pages/RegisterSitePage.vue');
const UnitHistory = () => import('../pages/units/UnitHistoryPage.vue');

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: { name: 'Root' },
  },
  {
    path: '/',
    name: 'Root',
    redirect: { name: 'Sites' },
  },
  {
    path: '/register-site',
    name: 'RegisterSite',
    component: RegisterSite,
  },
  {
    path: '/sites',
    name: 'Sites',
    component: Sites,
  },
  {
    path: '/sites/:thingId',
    name: 'Site',
    component: Site,
    props: true,
  },
  {
    path: '/sites/:thingId/unit/:unitId',
    name: 'Unit',
    component: Unit,
    props: true,
  },
  {
    path: '/sites/:thingId/unit/:unitId/history',
    name: 'UnitHistory',
    component: UnitHistory,
    props: true,
  },
];

export const router = new VueRouter({ mode: 'history', routes });
