import { Loader } from '@googlemaps/js-api-loader';
import { Coordinates } from '../models/Coordinates';
import MarkerCluster from '@googlemaps/markerclustererplus';
import { GOOGLE_MAPS_API_KEY } from '../config';

export const GoogleMapsLoader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: 'quarterly',
  libraries: ['places'],
});

export const defaultZoom = 6;
export function getDefaultCoordinates(): Promise<Coordinates> {
  const stockholm = {
    latitude: 59.325112,
    longitude: 18.071274,
  };

  return new Promise(resolve => {
    if (window?.navigator?.geolocation) {
      window?.navigator?.geolocation?.getCurrentPosition(
        ({ coords }) => {
          resolve(coords);
        },
        () => resolve(stockholm)
      );
    } else {
      resolve(stockholm);
    }
  });
}
export function createMarkerCluster(
  map: google.maps.Map,
  markers: google.maps.Marker[]
): MarkerCluster {
  return new MarkerCluster(map, markers, {
    maxZoom: 18,
    styles: [
      {
        url: `${window.location.origin}/img/maps/circle-green.png`,
        fontFamily: "'Swegon Brown',Arial,sans-serif",
        height: 48,
        width: 48,
        fontWeight: 'normal',
        textSize: 20,
        anchorText: [14, 0],
        textColor: 'white',
      },
    ],
  });
}
