import { i18n } from '../locales/i18n';
import { FormAutocompleteValue } from './FormAutocompleteValue';
import { FormTypeValue } from './FormTypeValue';
import { formValidator, FormValidatorFunction } from './FormValidator';
import { getProxyTTLs } from './ProxyUtils';
import { productTypes } from './UnitUtils';

export type FormBuilder = {
  'append-outer-icon'?: string;
  'item-value'?: string;
  'prepend-inner-icon'?: string;
  autocomplete?: FormAutocompleteValue;
  class?: string[];
  color?: string;
  items?: unknown[];
  label: unknown;
  outlined?: true;
  placeholder: unknown;
  rules?: FormValidatorFunction[];
  type: FormTypeValue;
};

const defaultFormBuilder: FormBuilder = {
  class: ['rounded-0'],
  color: 'accent',
  label: '',
  outlined: true,
  placeholder: '',
  type: 'text',
};

export const formBuilder: Record<string, FormBuilder> = {
  Default: {
    ...defaultFormBuilder,
  },
  Username: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-account',
    autocomplete: 'username',
    label: i18n.t('form.label.username'),
    placeholder: i18n.t('form.label.username'),
    type: 'text',
    rules: [
      formValidator.required(),
      formValidator.noWhiteSpace(),
      formValidator.minLength({ limit: 3 }),
    ],
  },
  Email: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-email',
    autocomplete: 'email',
    label: i18n.t('form.label.email'),
    placeholder: i18n.t('form.label.email'),
    type: 'email',
    rules: [formValidator.required(), formValidator.email()],
  },
  Password: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-key',
    autocomplete: 'current-password',
    label: i18n.t('form.label.password'),
    placeholder: i18n.t('form.label.password'),
    rules: [
      formValidator.required(),
      formValidator.passwordAllowedCharacters(),
      formValidator.maxIdenticalCharactersInRow({ limit: 2 }),
      formValidator.maxUpperOrLowerCharactersInRow({ limit: 3 }),
      formValidator.minUpperCharacterCount({ limit: 1 }),
      formValidator.minLowerCharacterCount({ limit: 1 }),
      formValidator.minNumberCount({ limit: 1 }),
      formValidator.minLength({ limit: 8 }),

      formValidator.password(),
    ],
    type: 'password',
  },
  ConfirmPassword: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-key',
    autocomplete: 'off',
    label: i18n.t('form.label.confirmPassword'),
    placeholder: i18n.t('form.label.confirmPassword'),
    rules: [formValidator.required()],
    type: 'password',
  },
  FirstName: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-account',
    autocomplete: 'given-name',
    label: i18n.t('form.label.firstName'),
    placeholder: i18n.t('form.label.firstName'),
    type: 'text',
    rules: [formValidator.required()],
  },
  LastName: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-account',
    autocomplete: 'family-name',
    label: i18n.t('form.label.lastName'),
    placeholder: i18n.t('form.label.lastName'),
    type: 'text',
    rules: [formValidator.required()],
  },
  MobilePhone: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-phone',
    autocomplete: 'tel',
    label: i18n.t('form.label.mobilePhone'),
    placeholder: i18n.t('form.label.mobilePhone'),
    type: 'tel',
    rules: [formValidator.required(), formValidator.phoneNumber()],
  },
  OptionalMobilePhone: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-phone',
    autocomplete: 'tel',
    label: i18n.t('form.label.mobilePhone'),
    placeholder: i18n.t('form.label.mobilePhone'),
    type: 'tel',
    rules: [formValidator.phoneNumber()],
  },
  Company: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-office-building',
    autocomplete: 'organization',
    label: i18n.t('form.label.company'),
    placeholder: i18n.t('form.label.company'),
    type: 'text',
  },
  Country: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-map',
    autocomplete: 'country',
    label: i18n.t('form.label.country'),
    placeholder: i18n.t('form.label.country'),
    type: 'text',
    rules: [formValidator.required()],
  },
  Address: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-home',
    autocomplete: 'street-address',
    label: i18n.t('form.label.address'),
    placeholder: i18n.t('form.label.address'),
    type: 'text',
  },
  Zip: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-home',
    autocomplete: 'postal-code',
    label: i18n.t('form.label.zip'),
    placeholder: i18n.t('form.label.zip'),
    type: 'text',
  },
  City: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-home',
    autocomplete: 'address-level2',
    label: i18n.t('form.label.city'),
    placeholder: i18n.t('form.label.city'),
    type: 'text',
  },
  RequestAccessTo: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-database',
    autocomplete: 'on',
    label: i18n.t('form.label.requestAccessTo'),
    placeholder: i18n.t('form.label.requestAccessTo'),
    type: 'text',
    rules: [formValidator.required()],
  },
  ContactAt: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-contacts',
    autocomplete: 'on',
    label: i18n.t('form.label.contactAt'),
    placeholder: i18n.t('form.label.contactAt'),
    type: 'text',
    rules: [formValidator.required()],
  },
  SiteSearch: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-magnify',
    autocomplete: 'off',
    label: i18n.t('form.label.searchSite'),
    placeholder: i18n.t('form.label.searchSite'),
    type: 'search',
  },
  Search: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-magnify',
    autocomplete: 'off',
    label: i18n.t('form.label.search'),
    placeholder: i18n.t('form.label.search'),
    type: 'search',
  },
  Name: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-label',
    autocomplete: 'off',
    label: i18n.t('form.label.name'),
    placeholder: i18n.t('form.label.name'),
    type: 'text',
    rules: [formValidator.required()],
  },
  Description: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-comment-question',
    autocomplete: 'off',
    label: i18n.t('form.label.description'),
    placeholder: i18n.t('form.label.description'),
  },
  ProxyTTL: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-clock',
    items: getProxyTTLs(),
    'item-value': 'proxyTTL',
    autocomplete: 'off',
    label: i18n.t('form.label.connectFor'),
    placeholder: i18n.t('form.label.connectFor'),
  },
  IpAddress: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-ip-network',
    autocomplete: 'off',
    label: i18n.t('form.label.ipAddress'),
    placeholder: i18n.t('form.label.ipAddress'),
    rules: [formValidator.required(), formValidator.ipAddress()],
  },
  Product: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-clock',
    items: productTypes,
    'item-value': 'productType',
    autocomplete: 'off',
    label: i18n.t('form.label.product'),
    placeholder: i18n.t('form.label.product'),
    rules: [formValidator.required()],
  },
  Location: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-map',
    autocomplete: 'off',
    label: i18n.t('form.label.location'),
    placeholder: i18n.t('form.label.location'),
    rules: [formValidator.required(), formValidator.coordinates()],
  },
  RouterSerialNumber: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-router-wireless',
    autocomplete: 'off',
    label: i18n.t('form.label.routerSerialNumber'),
    placeholder: i18n.t('form.label.routerSerialNumber'),
    rules: [formValidator.required()],
  },
  DatePicker: {
    ...defaultFormBuilder,
    'prepend-inner-icon': 'mdi-calendar',
    autocomplete: 'off',
  },
};
