var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","absolute":"","flat":"","tile":"","color":"grey lighten-4"}},[_c('v-container',{staticClass:"d-flex align-center"},[_c('v-toolbar-title',{staticClass:"d-flex"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-img',{staticClass:"mr-10",attrs:{"src":require("@/assets/img/swegonLogo.svg"),"alt":_vm.$t('app.swegonConnect'),"contain":"","width":"120"}}):_vm._e()],1),_c('router-link',{staticClass:"cursor-pointer accent--text",attrs:{"to":{ name: 'Root' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('v-toolbar-title',{on:{"click":navigate}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('app.swegon'))+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('app.connect')))])])]}}])}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"accent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","exact":"","color":"accent","to":{ name: 'Sites' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('component.theAppBar.sites')))])]),_c('v-tooltip',{attrs:{"bottom":"","color":"accent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","exact":"","color":"accent","to":{ name: 'RegisterSite' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('component.theAppBar.registerSite')))])]),_c('v-menu',{attrs:{"offset-y":"","tile":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"accent"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","exact":"","color":"accent"}},'v-btn',Object.assign({}, attrs, tooltipAttrs),false),Object.assign({}, on, tooltipOn)),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.currentUserFullName))])])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.onClickLogout}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t('component.theAppBar.signOut')))])])],1)],1),_c('v-divider'),_c('v-subheader',[_vm._v(_vm._s(_vm.$t('component.theAppBar.changeLanguage')))]),_c('v-list-item-group',{model:{value:(_vm.$i18n.locale),callback:function ($$v) {_vm.$set(_vm.$i18n, "locale", $$v)},expression:"$i18n.locale"}},_vm._l((_vm.languages),function(language){return _c('v-list-item',{key:_vm.$stringify(language),attrs:{"input-value":language.value,"value":language.value}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(language.text)+" ")])],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }