import axios from 'axios';
import { API_KEY } from '../config';
import { router } from '../router';
import { AuthService } from '../services/auth/AuthService';

export const AxiosInstance = axios.create({
  headers: {
    'x-api-key': API_KEY,
    'content-type': 'application/json; charset=utf-8',
  },
});

AxiosInstance.interceptors.response.use(undefined, async error => {
  const status = error?.response?.status || -1;
  if (status === -1 || status === 401) {
    AuthService.signOut();
    router.go(0);
  } else {
    throw error;
  }
});
