import { GetCredentialsForIdentityResponse } from 'aws-sdk/clients/cognitoidentity';
import { AxiosInstance } from '../../api/axios';
import { CLOUD_REST_API_BASE_URL, STORAGE_PREFIX } from '../../config';
import { isDefined } from '../../utils/Utils';
import { CognitoService } from '../cognito/CognitoService';
import { AuthServiceInterface } from './AuthServiceInterface';
import { GetCredentials } from './models/GetCredentials';
import { LoginCredentials } from './models/LoginCredentials';
import { LoginResponse } from './models/LoginResponse';
import { LoginUser } from './models/LoginUser';
import { SignUpParameters } from './models/SignUpParameters';
import { SignUpRequest } from './models/SignUpRequest';
import { SignUpResponse } from './models/SignUpResponse';

export const DEFAULT_REFRESH_TIME_IN_MS = 60 * 60 * 1000;

class AuthServiceImplementation implements AuthServiceInterface {
  private get credentialsKey(): string {
    return `${STORAGE_PREFIX}:credentials`;
  }

  private getLocalAuthCredentials(): LoginCredentials {
    return JSON.parse(localStorage.getItem(this.credentialsKey) ?? '{}');
  }

  private removeLocalAuthCredentials(): void {
    localStorage.removeItem(this.credentialsKey);
  }

  private setLocalAuthCredentials(credentials: LoginCredentials): void {
    localStorage.setItem(
      this.credentialsKey,
      JSON.stringify(credentials ?? {})
    );
  }

  private get cognitoCredentialsKey(): string {
    return `${STORAGE_PREFIX}:cognitoCredentials`;
  }

  private getLocalCognitoCredentials(): GetCredentialsForIdentityResponse {
    return JSON.parse(localStorage.getItem(this.cognitoCredentialsKey) ?? '{}');
  }

  private removeLocalCognitoCredentials(): void {
    localStorage.removeItem(this.cognitoCredentialsKey);
  }

  private setLocalCognitoCredentials(
    credentials: GetCredentialsForIdentityResponse
  ): void {
    localStorage.setItem(
      this.cognitoCredentialsKey,
      JSON.stringify(credentials ?? {})
    );
  }

  private get userKey(): string {
    return `${STORAGE_PREFIX}:user`;
  }

  private getLocalUser(): LoginUser {
    return JSON.parse(localStorage.getItem(this.userKey) ?? '{}');
  }

  private removeLocalUser(): void {
    localStorage.removeItem(this.userKey);
  }

  private setLocalUser(user: LoginUser): void {
    localStorage.setItem(this.userKey, JSON.stringify(user ?? {}));
  }

  private get refreshTimeKey(): string {
    return `${STORAGE_PREFIX}:refreshTime`;
  }

  setRefreshTime(timeInMs: number): void {
    localStorage.setItem(this.refreshTimeKey, JSON.stringify(timeInMs));
  }

  getRefreshTimeInMs(): number {
    const halfRefreshTime = DEFAULT_REFRESH_TIME_IN_MS / 2;
    return parseInt(
      localStorage.getItem(this.refreshTimeKey) ?? String(halfRefreshTime)
    );
  }

  isLoggedIn(): boolean {
    const credentials = this.getLocalAuthCredentials();
    return (
      isDefined(credentials.identityId) &&
      isDefined(credentials.token) &&
      isDefined(credentials.refreshToken)
    );
  }

  isRootUser(): boolean {
    const { domainName } = this.getCurrentUser();

    return domainName.toLowerCase() === 'root';
  }

  isOSSUser(): boolean {
    const { domainName } = this.getCurrentUser();

    return domainName.toLowerCase() === 'oss';
  }

  getToken(): string {
    const { token } = this.getLocalAuthCredentials();
    return token;
  }

  getCredentials(): GetCredentials {
    return {
      cognitoCredentials: this.getLocalCognitoCredentials(),
      authCredentials: this.getLocalAuthCredentials(),
    };
  }

  async signUp(parameters: SignUpParameters): Promise<SignUpResponse> {
    const ENDPOINT = '/auth/sign-up';
    const request: SignUpRequest = {
      ...parameters,
      userName: parameters.username,
      phone: parameters.mobilePhone,
      notes1: `Site: ${parameters.requestAccessTo}`,
      notes2: `Contact: ${parameters.contactAt}`,
    };

    const { data }: { data: SignUpResponse } = await AxiosInstance.post(
      `${CLOUD_REST_API_BASE_URL}${ENDPOINT}`,
      request
    );

    return data;
  }

  async confirmSignUp(token: string): Promise<void> {
    const ENDPOINT = '/auth/confirm-sign-up';

    const { data } = await AxiosInstance.post(
      `${CLOUD_REST_API_BASE_URL}${ENDPOINT}`,
      {
        token,
      }
    );

    return data;
  }

  signOut(): void {
    this.removeLocalAuthCredentials();
    this.removeLocalCognitoCredentials();
    this.removeLocalUser();
  }

  getCurrentUser(): LoginUser {
    return this.getLocalUser();
  }

  async login(username: string, password: string): Promise<LoginResponse> {
    const ENDPOINT = '/auth/login';

    const { data }: { data: LoginResponse } = await AxiosInstance.post(
      `${CLOUD_REST_API_BASE_URL}${ENDPOINT}`,
      {
        userName: username,
        password,
      }
    );

    this.setLocalAuthCredentials(data.credentials);
    this.setLocalUser(data.user);

    const cognitoCredentials = await CognitoService.getCredentials(
      data.credentials.identityId,
      data.credentials.token
    );
    this.setLocalCognitoCredentials(cognitoCredentials);

    this.setRefreshTime(DEFAULT_REFRESH_TIME_IN_MS);

    return data;
  }

  async refreshToken(): Promise<void> {
    const ENDPOINT = '/auth/refresh';
    const { refreshToken } = this.getLocalAuthCredentials();

    const { data }: { data: LoginResponse } = await AxiosInstance.post(
      `${CLOUD_REST_API_BASE_URL}${ENDPOINT}`,
      {
        refreshToken,
      }
    );
    this.setLocalAuthCredentials(data.credentials);
    this.setLocalUser(data.user);

    const cognitoCredentials = await CognitoService.getCredentials(
      data.credentials.identityId,
      data.credentials.token
    );
    this.setLocalCognitoCredentials(cognitoCredentials);

    this.setRefreshTime(DEFAULT_REFRESH_TIME_IN_MS);
  }

  async forgotPassword(username: string): Promise<void> {
    const ENDPOINT = '/auth/forgot-password';

    await AxiosInstance.post(`${CLOUD_REST_API_BASE_URL}${ENDPOINT}`, {
      userName: username,
    });
  }

  async setPassword(token: string, password: string): Promise<void> {
    const ENDPOINT = '/auth/set-password';

    await AxiosInstance.post(`${CLOUD_REST_API_BASE_URL}${ENDPOINT}`, {
      token,
      password,
    });
  }
}

export const AuthService = new AuthServiceImplementation();
