








import ThePreAppBar from './components/app/ThePreAppBar.vue';
import TheAppSnackbar from './components/app/TheAppSnackbar.vue';
import TheAppMain from './components/app/TheAppMain.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'PreApp',

  components: {
    TheAppMain,
    TheAppSnackbar,
    ThePreAppBar,
  },
});
