import { LoadingServiceInterface } from './LoadingServiceInterface';

class LoadingServiceImplementation implements LoadingServiceInterface {
  state = {
    isLoading: false,
  };

  setIsLoading(isLoading: boolean): void {
    Object.assign(this.state, { isLoading });
  }
}

export const LoadingService = new LoadingServiceImplementation();
