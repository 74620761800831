














import Vue from 'vue';
import TheLoadingOverlay from './TheLoadingOverlay.vue';

export default Vue.extend({
  name: 'TheAppMain',

  components: { TheLoadingOverlay },
});
