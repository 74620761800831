import { pickBy } from 'lodash-es';

export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function isString(value: unknown | null | undefined): value is string {
  return typeof value === 'string';
}

export function isNumber(value: unknown | null | undefined): value is number {
  const tryParseAsNumber = parseFloat(value as string);

  return !isNaN(tryParseAsNumber);
}

export function isEmpty(value: unknown | null | undefined): boolean {
  return isString(value) && value === '';
}

export function pickDefined<T extends object>(data: T): Partial<T> {
  return pickBy(data, isDefined);
}
