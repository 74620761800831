import CognitoIdentity, {
  GetCredentialsForIdentityResponse,
} from 'aws-sdk/clients/cognitoidentity';
import { ManifestService } from '../manifest/ManifestService';
import { CognitoServiceInterface } from './CognitoServiceInterface';

class CognitoServiceImplementation implements CognitoServiceInterface {
  async getCredentials(
    identityId: string,
    token: string
  ): Promise<GetCredentialsForIdentityResponse> {
    const manifest = await ManifestService.getManifest();

    const identity = new CognitoIdentity({ region: manifest.Region });

    const loginsKey = `cognito-idp.${manifest.Region}.amazonaws.com/${manifest.UserPool}`;
    const response = await identity
      .getCredentialsForIdentity({
        IdentityId: identityId,
        Logins: {
          [loginsKey]: token,
        },
      })
      .promise();

    return response;
  }
}

export const CognitoService = new CognitoServiceImplementation();
