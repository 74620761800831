









import TheAppBar from './components/app/TheAppBar.vue';
import TheAppMain from './components/app/TheAppMain.vue';
import TheAppSnackbar from './components/app/TheAppSnackbar.vue';
import TheTokenRefresh from './components/auth/TheTokenRefresh.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'App',

  components: {
    TheAppBar,
    TheAppMain,
    TheAppSnackbar,
    TheTokenRefresh,
  },
});
