import { DateTime } from 'luxon';
import Vue from 'vue';
import { isEmpty, isNumber, isString } from '../utils/Utils';

export const DateMixinObject = {
  methods: {
    $dateTime(value: unknown): string {
      if (!isEmpty(value) && isString(value)) {
        return DateTime.fromISO(value).toLocaleString(
          DateTime.DATETIME_MED_WITH_SECONDS
        );
      }

      if (isNumber(value) && String(value).length >= 12) {
        return DateTime.fromMillis(value).toLocaleString(
          DateTime.DATETIME_MED_WITH_SECONDS
        );
      } else if (isNumber(value)) {
        return DateTime.fromSeconds(value).toLocaleString(
          DateTime.DATETIME_MED_WITH_SECONDS
        );
      }

      return '';
    },
    $date(value: unknown): string {
      if (!isEmpty(value) && isString(value)) {
        return DateTime.fromISO(value).toLocaleString(DateTime.DATE_FULL);
      }

      if (isNumber(value) && String(value).length >= 12) {
        return DateTime.fromMillis(value).toLocaleString(DateTime.DATE_FULL);
      } else if (isNumber(value)) {
        return DateTime.fromSeconds(value).toLocaleString(DateTime.DATE_FULL);
      }

      return '';
    },
  },
};

export const DateMixin = Vue.extend(DateMixinObject);
