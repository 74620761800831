import { i18n } from '../locales/i18n';
import { isDefined, isEmpty, isString, isNumber } from './Utils';

export type FormValidatorFunction = (value: unknown) => boolean | string;

export type FormValidatorGenerator = (
  data?: Record<string, unknown>
) => FormValidatorFunction;

export const formValidator: Record<string, FormValidatorGenerator> = {
  required(): FormValidatorFunction {
    return function(value: unknown) {
      const isValid = isDefined(value) && value !== '';

      return isValid || (i18n.t('form.validator.required') as string);
    };
  },

  email() {
    return function(value: unknown) {
      // Regex from https://www.regexpal.com/?fam=104027.
      const emailRegexp = new RegExp(
        '^([a-z0-9_.+-]+)@([\\da-z.-]+)[.]([a-z.]{2,6})$'
      );
      const isValid = isString(value) && emailRegexp.test(value.toLowerCase());

      return isValid || (i18n.t('form.validator.email') as string);
    };
  },

  /**
   * @deprecated
   * Preferably use following functions individually:
   * passwordAllowedCharacters(),
   * maxIdenticalCharactersInRow(),
   * maxUpperOrLowerCharactersInRow(),
   * minUpperCharacterCount(),
   * minNumberCount(),
   * minLowerCharacterCount(),
   * minLength()
   */
  password() {
    return function(value: unknown) {
      const minLengthRegexp = new RegExp('.{8,}');
      const numberRegexp = new RegExp('\\d+');
      const allowedCharactersRegexp = new RegExp(
        '^[\\dA-Za-zÅÄÖåäö#$\\-!@%&/()?+*]+$'
      );
      const maxLowerCharactersInRow = new RegExp('[a-zåäö]{4}');
      const maxUpperCharactersInRow = new RegExp('[A-ZÅÄÖ]{4}');
      const maxIdenticalCharactersInRow = /(.)\1{2}/;

      const isValid =
        isString(value) &&
        minLengthRegexp.test(value) &&
        numberRegexp.test(value) &&
        allowedCharactersRegexp.test(value) &&
        !maxLowerCharactersInRow.test(value) &&
        !maxUpperCharactersInRow.test(value) &&
        !maxIdenticalCharactersInRow.test(value);

      return isValid || (i18n.t('form.validator.password') as string);
    };
  },

  passwordAllowedCharacters() {
    return function(value: unknown) {
      const allowedCharactersRegexp = new RegExp(
        '^[\\dA-Za-zÅÄÖåäö#$\\-!@%&/()?+*]+$'
      );

      const isValid =
        isString(value) &&
        (isEmpty(value) || allowedCharactersRegexp.test(value));

      return (
        isValid ||
        (i18n.t('form.validator.passwordAllowedCharacters') as string)
      );
    };
  },

  maxUpperOrLowerCharactersInRow({ limit } = {}) {
    return function(value: unknown) {
      if (!isNumber(limit)) {
        throw new Error('limit must be a number');
      }

      const upperLimit = limit + 1;
      const maxLowerCharactersInRowRegexp = new RegExp(
        `(?:[a-zåäö]{${upperLimit}}|[A-ZÅÄÖ]{${upperLimit}})`
      );

      const isValid =
        isString(value) &&
        (isEmpty(value) || !maxLowerCharactersInRowRegexp.test(value));

      return (
        isValid ||
        (i18n.t('form.validator.maxUpperOrLowerCharactersInRow', {
          limit,
        }) as string)
      );
    };
  },

  maxIdenticalCharactersInRow({ limit } = {}) {
    return function(value: unknown) {
      const maxIdenticalCharactersInRowRegexp = new RegExp(`(.)\\1{${limit}}`);

      const isValid =
        isString(value) &&
        (isEmpty(value) || !maxIdenticalCharactersInRowRegexp.test(value));

      return (
        isValid ||
        (i18n.t('form.validator.maxIdenticalCharactersInRow', {
          limit,
        }) as string)
      );
    };
  },

  minUpperCharacterCount({ limit } = {}) {
    return function(value: unknown) {
      const minUpperCharacterCountRegexp = new RegExp(`[A-ZÅÄÖ]{${limit}}`);

      const isValid =
        isString(value) && minUpperCharacterCountRegexp.test(value);

      return (
        isValid ||
        (i18n.t('form.validator.minUpperCharacterCount', { limit }) as string)
      );
    };
  },

  minLowerCharacterCount({ limit } = {}) {
    return function(value: unknown) {
      const minLowerCharacterCountRegexp = new RegExp(`[a-zåäö]{${limit}}`);

      const isValid =
        isString(value) && minLowerCharacterCountRegexp.test(value);

      return (
        isValid ||
        (i18n.t('form.validator.minLowerCharacterCount', { limit }) as string)
      );
    };
  },

  minNumberCount({ limit } = {}) {
    return function(value: unknown) {
      const minLowerCharacterCountRegexp = new RegExp(`[\\d]{${limit}}`);

      const isValid =
        isString(value) && minLowerCharacterCountRegexp.test(value);

      return (
        isValid ||
        (i18n.t('form.validator.minNumberCount', { limit }) as string)
      );
    };
  },

  confirmPassword({ password } = {}) {
    return function(value: unknown) {
      const isValuesEqual = password === value;
      const isValuesUndefined = !isDefined(password) || !isDefined(value);
      const isValid = isValuesUndefined || isValuesEqual;

      return isValid || (i18n.t('form.validator.confirmPassword') as string);
    };
  },

  phoneNumber() {
    return function(value: unknown) {
      const phoneNumberRegexp = new RegExp('^(\\+){1}(\\d){8,15}$');
      let isValid = !isDefined(value);

      if (isString(value)) {
        isValid = isEmpty(value) || phoneNumberRegexp.test(value);
      }

      return isValid || (i18n.t('form.validator.phoneNumber') as string);
    };
  },

  ipAddress() {
    return function(value: unknown) {
      const ipAddressRegexp = new RegExp(
        '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'
      );

      const isValid =
        isString(value) && (isEmpty(value) || ipAddressRegexp.test(value));

      return isValid || (i18n.t('form.validator.ipAddress') as string);
    };
  },

  coordinates() {
    return function(value: unknown) {
      const coordinatesRegexp = new RegExp(
        '^[-+]?([1-8]?\\d(.\\d+)?|90(.0+)?),\\s*[-+]?(180(.0+)?|((1[0-7]\\d)|([1-9]?\\d))(.\\d+)?)$'
      );

      const isValid =
        isString(value) && (isEmpty(value) || coordinatesRegexp.test(value));

      return isValid || (i18n.t('form.validator.coordinates') as string);
    };
  },

  minLength({ limit } = {}) {
    return function(value: unknown) {
      const isValid =
        isString(value) && value.trim().length >= (limit as number);

      return (
        isValid || (i18n.t('form.validator.minLength', { limit }) as string)
      );
    };
  },

  maxLength({ limit } = {}) {
    return function(value: unknown) {
      const isValid =
        isString(value) && value.trim().length <= (limit as number);

      return (
        isValid || (i18n.t('form.validator.maxLength', { limit }) as string)
      );
    };
  },

  noWhiteSpace() {
    return function(value: unknown) {
      const hasWhiteSpaceRegexp = new RegExp('\\s+');

      const isValid =
        isString(value) && (isEmpty(value) || !hasWhiteSpaceRegexp.test(value));

      return isValid || (i18n.t('form.validator.noWhitespace') as string);
    };
  },
};
