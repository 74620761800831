import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { STORAGE_PREFIX } from '../config';
import { en } from './en';
import { sv } from './sv';

Vue.use(VueI18n);

export const languageLocalStorageKey = `${STORAGE_PREFIX}:language`;

export function setLocalStorageLanguage(language: string): void {
  localStorage.setItem(languageLocalStorageKey, language);
}

export function getLocalStorageLanguage(): string | null {
  return localStorage.getItem(languageLocalStorageKey);
}

export const supportedLanguages = [
  {
    text: 'English',
    value: 'en',
  },
  {
    text: 'Svenska',
    value: 'sv',
  },
];

export const i18n = new VueI18n({
  locale: getLocalStorageLanguage() ?? 'en',
  messages: {
    en,
    sv,
  },
});
