























import Vue from 'vue';

export default Vue.extend({
  name: 'LoadingOverlay',

  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
});
