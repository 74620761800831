import { Optional } from '@/utils/Optional';

export enum ProductType {
  Compact = 'COMPACT (2007-)',
  GoldB = 'GOLD ver.B (2002-2005)',
  GoldC = 'GOLD ver.C (2005-2009)',
  GoldD = 'GOLD ver.D (2009-2013)',
  GoldE = 'GOLD ver.E (2013-2017)',
  GoldF = 'GOLD ver.F (2017-)',
  GoldLP = 'GOLD LP (2009-)',
  Nestor = 'NESTOR (2013-)',
  Superwise2010 = 'SUPERWISE (2010-2017)',
  Superwise2017 = 'SUPERWISE (2017-)',
  Other = 'other',
}

//Translation from producttypeid from collector, this is the master that should be used when determining id in collector
export function getProductType(id: Optional<number>): Optional<ProductType> {
  switch (id) {
    case 0:
      return ProductType.Compact;
    case 1:
      return ProductType.GoldB;
    case 2:
      return ProductType.GoldC;
    case 3:
      return ProductType.GoldD;
    case 4:
      return ProductType.GoldE;
    case 5:
      return ProductType.GoldF;
    case 6:
      return ProductType.GoldLP;
    case 7:
      return ProductType.Nestor;
    case 8:
      return ProductType.Superwise2010;
    case 9:
      return ProductType.Superwise2017;
    case 10:
      return ProductType.Other;
  }
  return null;
}
