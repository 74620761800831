




import Vue from 'vue';
import LoadingOverlay from '../common/LoadingOverlay.vue';
import { LoadingService } from '../../services/loading/LoadingService';

export default Vue.extend({
  name: 'TheLoadingOverlay',

  components: {
    LoadingOverlay,
  },

  data() {
    return {
      state: LoadingService.state,
    };
  },
});
